import { Link } from "react-router-dom"

export const Home = () => {
    return (
        <>
            <div className="flex">
                <Link className="link" to="/vika">Я Віка</Link>
            </div>
            <div className="flex">
                <Link className="link" to="/max">Я Макс</Link>
            </div>
        </>
    )
}