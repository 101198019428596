import React from 'react';
import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { Max } from './Max';
import { Vika } from './Vika';
import { Home } from './Home';

function App() {
  return (
    (
      <Router>
        <div className='main'>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/vika" element={<Vika/>} />
            <Route path="/max" element={<Max/>} />
          </Routes>
        </div>
      </Router>
    )
  );
}

export default App;
