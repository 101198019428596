import axios from 'axios';
import { ReactComponent as Beach } from './beach_access.svg';
const url = 'https://flat-heart-b349.serhii-want.workers.dev/';

export const Max = () => {
    const wantHandler = async (option: number) => {
        const data = {
            data: {
                from: 'max',
                option: option
            }
        }
        await axios.post(url, data);
    }

    return (
        <div>
            <h1 className='header-main'>СЬОГОДНІ Я</h1>
            <div className='btn-block'>
                <button className='btn' type="button" onClick={() => wantHandler(1)}>Хочу жрать</button>
            </div>
            <div className='btn-block'>
                <button className='btn' type="button" onClick={() => wantHandler(2)}>Хочу пива</button>
            </div>
            <div className='btn-block'>
                <button className='btn' type="button" onClick={() => wantHandler(3)}>Хочу сексу</button>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '200px'}}>
                <Beach className="svg-filter" onClick={() => window.open('https://drive.google.com/file/d/1YEOzF6oy1lw-gVpwT0NqG1s7_ou1AKil/view?usp=sharing', '_blank')} />
            </div>
        </div>
    )
}