import axios from "axios";
import { ReactComponent as Beach } from './beach_access.svg';
const url = 'https://flat-heart-b349.serhii-want.workers.dev/';

export const Vika = () => {
    const wantHandler = async (option: number) => {
        const data = {
            data: {
                from: 'vika',
                option: option
            }
        }
        await axios.post(url, data);
    }

    return (
        <div style={{ width: '100%' }}>
            <h1 className="header-main">СЬОГОДНІ Я</h1>
            <div className='btn-block'>
                <button className="btn" type="button" onClick={() => wantHandler(1)}>Хочу вкусняшок</button>
            </div>
            <div className='btn-block'>
                <button className="btn" type="button" onClick={() => wantHandler(2)}>Хочу гулять</button>
            </div>
            <div className='btn-block'>
                <button className="btn" type="button" onClick={() => wantHandler(3)}>Хочу обнімашок</button>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: '200px'}}>
                <Beach className="svg-filter" onClick={() => window.open('https://drive.google.com/file/d/1YEOzF6oy1lw-gVpwT0NqG1s7_ou1AKil/view?usp=sharing', '_blank')} />
            </div>
        </div>
    )
}